import React from "react"
import { Icon } from "antd"
import { graphql, Link, useStaticQuery } from "gatsby"
import style from "./SportSection.module.scss"

export default ({ sport: { name, slug } }) => {
  const { image } = useStaticQuery(graphql`
    query {
      image: contentfulAsset(title: { eq: "LLC - Death Valley" }) {
        description
        fluid(maxWidth: 300) {
          src
        }
      }
    }
  `)

  return (
    <div className={style.wrapper}>
      <Link to={`/sports/${slug}/`}>
        <img
          alt={image.description}
          className={style.bgImage}
          src={image.fluid.src}
        />
        <div className={style.view}>
          View <Icon className={style.arrow} type="arrow-right" />
        </div>
        <div className={style.name}>{name.toUpperCase()}</div>
      </Link>
    </div>
  )
}
