import React from "react"
import { Col, Divider, List, Row, Typography } from "antd"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Shell from "../components/Shell/Shell"
import Meta from "../components/Meta/Meta"
import SeoHeader from "../components/SeoHeader/SeoHeader"
import { EventsList } from "../components/SportingEvent/SportingEvent"
import SportSection from "../components/SportSection/SportSection"
import { getEntries, resolveApiResponse } from "../utils/contentful"

const row = {
  align: `middle`,
  gutter: [24, 24],
  justify: `center`,
  type: `flex`,
}

const fullCol = {
  xs: 22,
  sm: 20,
  md: 18,
  lg: 16,
  xl: 14,
}

export default ({ data: { hero, sports } }) => {
  const [events, setEvents] = React.useState([])
  const [noEvents, setNoEvents] = React.useState(false)

  React.useEffect(() => {
    const getEvents = async () => {
      const { data, status } = await getEntries({
        content_type: `sportingEvent`,
        order: `fields.eventStart`,
        "fields.eventStart[gte]": new Date().toISOString(),
        limit: 3,
      })

      if (status === 200) {
        const evts = resolveApiResponse(data)
        if (evts.length === 0) {
          setNoEvents(true)
        } else {
          setEvents(evts)
        }
      }
    }
    getEvents()
  }, [])

  return (
    <Shell>
      <Meta
        seo={{
          title: `Sporting Events in Clemson SC | Lakeside Lodge Clemson`,
          img: hero.fluid.src,
          imgAlt: hero.description,
          desc: `Support your sports team here in Clemson, SC. Attend a sporting event, \
            cheer them on, then head back to Lakeside Lodge so that you can rest and \
            get ready for the next big event.`,
        }}
        uri="/sports/"
      />
      <Row style={{ marginBottom: 16, width: `100vh` }}>
        <Img
          alt={hero.description}
          fluid={hero.fluid}
          style={{ height: `40vh`, width: `100vw` }}
        />
      </Row>
      <Row {...row}>
        <Col {...fullCol}>
          <SeoHeader
            human={{ content: `Superfans Welcome` }}
            seo={{ content: `Sports in Clemson` }}
          />
          <Typography.Paragraph>
            No matter what your favorite sport is, we welcome all superfans. And
            we’ve got plenty of ways to keep you entertained when you’re not
            watching or participating in a game. From the outdoor pool and hot
            tub, to the fitness center and giant firepit, to our on-site
            restaurant Traditions on the Lake, our amenities and sense of
            community make for repeat stays - whether you’re in town for the Big
            Game or just a little relaxation.
          </Typography.Paragraph>
          <br />
          <List
            dataSource={sports.nodes}
            grid={{ gutter: 24, xs: 1, sm: 2, xxl: 3 }}
            renderItem={({ name, slug }, i) => (
              <List.Item key={i}>
                <SportSection sport={{ name, slug }} />
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row {...row}>
        <Col {...fullCol}>
          <Divider />
        </Col>
      </Row>
      <Row {...row}>
        <Col {...fullCol}>
          <SeoHeader
            human={{ content: `Make A Weekend Out of It`, level: 3 }}
            seo={{ content: `Upcoming Events`, level: 2 }}
          />
          <EventsList events={events} noEvents={noEvents} />
        </Col>
      </Row>
    </Shell>
  )
}

export const query = graphql`
  query {
    hero: contentfulAsset(title: { eq: "LLC - Death Valley" }) {
      description
      fluid(maxWidth: 1500) {
        ...GatsbyContentfulFluid
      }
    }
    sports: allSportsJson {
      nodes {
        name
        slug
      }
    }
  }
`
